import React from 'react';
import ReactDOM from 'react-dom';
import Sports from './Sports';
import ImgSrc from './libraries/ImgSrc';
import { WorkoutsProvider } from './libraries/WorkoutData';
import { BrowserRouter as Router } from "react-router-dom";

window.ImgSrc = new ImgSrc();

ReactDOM.render(<WorkoutsProvider><Router><Sports /></Router></WorkoutsProvider>, document.getElementById('root'));
