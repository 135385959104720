import axios from 'axios';

class apiHttp {
    static getUrl() {
        return process.env.NODE_ENV === 'development' ? 'http://www.sports.local:8080' : 'https://api-sports.rgrimaldi.com';
    }

    static buildParamsPost(object) {
        const dataPost = new FormData();
        for (let key in object) {
            dataPost.append(key, object[key]);
        }

        return dataPost;
    }

    static getByActivity(activityName) {
        return axios.get(
            this.getUrl() + '/welcome/showByActivity/' + activityName,
            {
                headers: {
                    'Content-Type' : 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }
        );
    }

    static getByFilters(filters) {
        return axios.get(
            this.getUrl() + '/welcome/showByFilter/',
            {
                params : filters,
                headers: {
                    'Content-Type' : 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }
        );
    }

    static getTags(activityId) {
        return axios.get(
            this.getUrl() + '/welcome/tags/' + activityId,
            {
                headers: {
                    'Content-Type' : 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }
        );
    }

    static linkTag(workoutId, tagId) {
        return axios.post(
            this.getUrl() + '/welcome/linkWorkoutToTag/',
            this.buildParamsPost({
                workout_id : workoutId,
                tag_id : tagId
            }),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }
        );
    }

    static linkNewTag(workoutId, tagName, tagType) {
        return axios.post(
            this.getUrl() + '/welcome/linkWorkoutToNewTag/',
            this.buildParamsPost({
                workout_id : workoutId,
                tag_name : tagName,
                tag_type : tagType
            }),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }
        );
    }
}

export default apiHttp;