import './css/sports.css';
import Workouts from './blocs/Workouts';
import { useRoutes } from "react-router-dom";

const Sports = () => {
    document.title = "Mon Sports";

    const routes = useRoutes([
        {
            path: "",
            element: <Workouts />,
            children: [
                { path: "/activities/", element: <Workouts />},
                { path: ":activity", element: <Workouts />},
                { path: "filter/:filterType/:filterValue", element: <Workouts />},
            ]
        }
    ]);

    return <div className="content">{routes}</div>;
}

export default Sports;
