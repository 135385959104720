import React, {createContext, useContext, useReducer} from 'react';
import apiHttp from '../libraries/apiHttp';
import Utils from '../libraries/Utils';


class Workouts {
    constructor() {
        this.data = []
    }

    get(activityName) {
        return Object.keys(this.data).length > 0 && this.data[activityName] ? this.data[activityName].workouts : [];
    }

    getState(activityName, key) {
        return Object.keys(this.data).length > 0 && this.data[activityName] && this.data[activityName][key] ? this.data[activityName][key] : {};
    }

    getByFilter(activityName, filter) {
        const workouts = this.get(activityName);
        const returnData = [];

        for (const i in workouts) {
            if (filter.key === 'year') {
                if (parseInt(Utils.getYearOfDate(workouts[i].start_time)) === parseInt(filter.value)) {
                    returnData.push(workouts[i]);
                }
            } else if (filter.key === 'month') {
                if (Utils.getMonthAndYearOfDate(workouts[i].start_time) === filter.value) {
                    returnData.push(workouts[i]);
                }
            } else if (filter.key === 'week') {
                if (Utils.getMonthAndYearOfDate(workouts[i].start_time) + "-" + Utils.getWeekOfDate(workouts[i].start_time) === filter.value) {
                    returnData.push(workouts[i]);
                }
            } else if (filter.key === 'type_id') {
                for (const j in workouts[i].all_tags) {
                    if (parseInt(workouts[i].all_tags[j].type) === parseInt(filter.value)) {
                        returnData.push(workouts[i]);
                    }
                }
            } else if (filter.key === 'tags') {
                for (const j in workouts[i].all_tags) {
                    if (parseInt(workouts[i].all_tags[j].id) === parseInt(filter.value)) {
                        returnData.push(workouts[i]);
                    }
                }
            }
        }

        return returnData;
    }

    getByFilterAndSort(activityName, filter, orderBy, orderDesc) {
        let workouts = [];
        if ('stats' in filter && filter.stats) {
            workouts = this.getState(activityName, filter.stats);
        } else if ('key' in filter && filter.key) {
            workouts = this.getByFilter(activityName, filter);
        } else {
            workouts = this.get(activityName);
        }

        const sortable = [];
        for (let i in workouts) {
            if (orderBy === 'avg_dst') {
                const distance = Math.round(parseInt(workouts[i].total_distance)/10)/100;
                sortable.push([i, Math.round(distance/workouts[i].count*100)/100]);
            } else {
                sortable.push([i, workouts[i][orderBy]]);
            }
        }
        if (orderDesc === 'asc') {
            sortable.sort(function(a, b) {
                return a[1] - b[1];
            });
        } else {
            sortable.sort(function(a, b) {
                return b[1] - a[1];
            });
        }

        const returnWorkout = [];
        for (let i in sortable) {
            const k = sortable[i][0];
            returnWorkout[i] = workouts[k];
        }

        return returnWorkout;
    }

    set(activityName, workouts) {
        this.data[activityName] = {};
        this.data[activityName].workouts = workouts;
        this.data[activityName].by_tags = {};
        this.data[activityName].by_years = {};
        this.data[activityName].by_months = {};
        this.data[activityName].by_week = {};

        this.data[activityName].total = {
            total_time : 0,
            total_distance : 0,
            avg_speed : 0,
            energy : 0,
            count : 0
        };

        for (const i in workouts) {
            this.addTotal(activityName, workouts[i]);

            const tags = workouts[i].all_tags ? workouts[i].all_tags : [];

            for (const j in tags) {
                this.addStatsForType(activityName, tags[j].type, workouts[i]);
            }

            this.addStatsForYear(activityName, Utils.getYearOfDate(workouts[i].start_time), workouts[i]);
            this.addStatsForMonth(activityName, Utils.getMonthAndYearOfDate(workouts[i].start_time), workouts[i]);
            this.addStatsForWeek(
                activityName,
                Utils.getMonthAndYearOfDate(workouts[i].start_time) + "-" + Utils.getWeekOfDate(workouts[i].start_time),
                Utils.getLabelWeekOfDate(workouts[i].start_time),
                workouts[i]
            );
        }
    }

    addTotal(activityName, workout) {
        this.data[activityName].total.total_time += parseInt(workout.total_time);
        this.data[activityName].total.total_distance += parseInt(workout.total_distance);
        this.data[activityName].total.avg_speed += parseFloat(workout.avg_speed);
        this.data[activityName].total.energy += parseInt(workout.energy);
        this.data[activityName].total.count += 1;
    }

    addStatsForType(activityName, key, workout) {
        const lableType = Utils.getLabelType();
        this.addIn(this.data[activityName].by_tags, key, workout);
        this.data[activityName].by_tags[key].key = lableType[key] ? lableType[key] : 'N.A.';
        this.data[activityName].by_tags[key].name = lableType[key] ? lableType[key] : 'N.A.';
        this.data[activityName].by_tags[key].filterValue = key;
    }

    addStatsForYear(activityName, key, workout) {
        this.addIn(this.data[activityName].by_years, key, workout);
        this.data[activityName].by_years[key].key = key;
        this.data[activityName].by_years[key].name = key;
        this.data[activityName].by_years[key].filterValue = key;
    }

    addStatsForMonth(activityName, key, workout) {
        this.addIn(this.data[activityName].by_months, key, workout);
        this.data[activityName].by_months[key].key = key.replace('-', '');
        this.data[activityName].by_months[key].name = key;
        this.data[activityName].by_months[key].filterValue = key;
    }

    addStatsForWeek(activityName, key, name, workout) {
        this.addIn(this.data[activityName].by_week, key, workout);
        this.data[activityName].by_week[key].key = key.replace('-', '');
        this.data[activityName].by_week[key].name = name;
        this.data[activityName].by_week[key].filterValue = key;
    }

    addIn(addIn, key, workout) {
        if (!addIn[key]) {
            addIn[key] = {
                total_time : 0,
                total_distance : 0,
                avg_speed : 0,
                energy : 0,
                count : 0
            };
        }
        addIn[key].total_time += parseInt(workout.total_time);
        addIn[key].total_distance += parseInt(workout.total_distance);
        addIn[key].avg_speed += parseFloat(workout.avg_speed);
        addIn[key].energy += parseInt(workout.energy);
        addIn[key].count += 1;
    }

    search(activityName) {
        return new Promise((resolve, reject) => {
            apiHttp.getByActivity(activityName).then(
                (res) => {
                    if (res && res.data && res.data.workouts) {
                        this.set(activityName, res.data.workouts);
                        resolve(res.data.workouts);
                        return;
                    }

                    reject({message:'aucune données', code:'400'});
                }
            ).catch(reject);
        });
    }
}


window.workoutData = new Workouts();

const initialState = {
    workouts: new Workouts(),
    loading : true
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'set':
            state.workouts.set(action.activityName, action.workouts);
            return {
                ...state,
                loading : false
            };
        default:
            return state;
   }
};

const WorkoutsContext = createContext();

export const WorkoutsData = WorkoutsContext.Consumer;
export const WorkoutsDataHook = () => useContext(WorkoutsContext);

export const WorkoutsProvider = ({children}) => (
    <WorkoutsContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </WorkoutsContext.Provider>
);
