import { RenderLoader, renderHeadList } from './RenderHTML';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Utils from '../libraries/Utils';
import RenderStats from './RenderStats';
import RenderWorkouts from './RenderWorkouts';
import { WorkoutsDataHook } from '../libraries/WorkoutData';

const Workouts = () => {
    const [filter, setFilter] = useState({key : null, value : null, stats : null});
    const {filterType, filterValue} = useParams();
    const [{workouts}, dispatch] = WorkoutsDataHook();

    if (filter.key !== filterType || filter.value !== filterValue) {
        setFilter({
            key : filterType,
            value : filterValue
        });
    }

    useEffect(() => {
        workouts.search('cycling').then(function(workouts) {
            dispatch({
                type: 'set',
                workouts: workouts,
                activityName: 'cycling'
            });
        }).catch(function(err) {
            console.log(err);
        });
    }, []);

    if (workouts.get('cycling').length > 0) {
        const totalStats = workouts.getState('cycling', 'total');
        const distance = Math.round(parseInt(totalStats.total_distance)/10)/100;
        let maxWorkout = 20;

        return <div className="content">
            <div className="lists">
                <div className="title">Total</div>
                <div className="data-grid cols-6">
                    {renderHeadList('total', '', '')}
                    <div className="box">{totalStats.count}</div>
                    <div className="box">{Utils.formatSeconds(totalStats.total_time)}</div>
                    <div className="box">{distance} km</div>
                    <div className="box">{Math.round(distance/totalStats.count*100)/100} km</div>
                    <div className="box">{Math.round(totalStats.avg_speed*100/totalStats.count)/100} km/h</div>
                    <div className="box">{totalStats.energy}</div>
                </div>
            </div>
            <div id="workouts-lists">
                <RenderWorkouts max={maxWorkout} filter={filter} />
            </div>
            <RenderStats title="Par Année" filterKey="year" keyStats="by_years" max="-1" />
            <RenderStats title="Par mois" filterKey="month" keyStats="by_months" max="10" />
            <RenderStats title="Par Semaine" filterKey="week" keyStats="by_week" max="10" />
            <RenderStats title="Par type de tags" filterKey="type_id" keyStats="by_tags" max="-1" />
        </div>;
    }

    return RenderLoader();
}

export default Workouts;