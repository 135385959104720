import { renderHeadList, sortWorkout, labelColOrderBy } from './RenderHTML';
import { useState } from 'react';
import { Link } from "react-router-dom";
import Utils from '../libraries/Utils';
import { WorkoutsDataHook } from '../libraries/WorkoutData';

const RenderStats = ({title, keyStats, max, filterKey}) => {
    const [orderBy, setOrderBy] = useState('total_distance');
    const [orderDesc, setOrderDesc] = useState(true);
    const [showAll, setShowAll] = useState(false);

    const [{workouts}, dispatch] = WorkoutsDataHook();

    const stats = workouts.getByFilterAndSort('cycling', {'stats' : keyStats}, orderBy, orderDesc ? 'desc' : 'asc');
    const lineStats = [];

    const orderChange = (e) => {
        if (e.target.classList.contains('order-by-icon')) {
            if (e.target.parentNode.dataset.orderBy) {
                if (e.target.parentNode.dataset.orderBy !== orderBy) {
                    setOrderBy(e.target.parentNode.dataset.orderBy);
                }
                if (e.target.parentNode.dataset.orderSort === 'desc' && !orderDesc) {
                    setOrderDesc(true);
                } else if (e.target.parentNode.dataset.orderSort !== 'desc' && orderDesc) {
                    setOrderDesc(false);
                }
            }
        }
        return false;
    }

    const toggleMore = (e) => {
        if (e.target.classList.contains('show-more')) {
            setShowAll(true);
        } else if (e.target.classList.contains('show-less')) {
            setShowAll(false);
        }
        return false;
    }

    max = showAll || parseInt(max) === -1 ? stats.length : max;

    for (let k = 0; k < max; k++) {
        const distance = Math.round(parseInt(stats[k].total_distance)/10)/100;

        lineStats.push([
            <div className="box filter" key={"w-"+stats[k].key+"-name"}>
                <Link to={Utils.getUrl() + 'filter/' + filterKey + '/' + stats[k].filterValue}>{stats[k].name}</Link>
            </div>,
            <div className="box" key={"w-"+stats[k].key+"-count"}>{stats[k].count}</div>,
            <div className="box" key={"w-"+stats[k].key+"-time"}>{Utils.formatSeconds(stats[k].total_time)}</div>,
            <div className="box" key={"w-"+stats[k].key+"-dst"}>{distance} km</div>,
            <div className="box" key={"w-"+stats[k].key+"-avg-dst"}>{Math.round(distance/stats[k].count*100)/100} km</div>,
            <div className="box" key={"w-"+stats[k].key+"-avg-speed"}>{Math.round(stats[k].avg_speed*100/stats[k].count)/100} km/h</div>,
            <div className="box" key={"w-"+stats[k].key+"-energy"}>{stats[k].energy}</div>
        ]);
    }

    const countElem = Object.keys(stats).length;

    return <div className="lists">
                <div className="title">
                    {countElem > max ? 'Top ' + max + ' ' : ''}{title}<br clear="all"/>{labelColOrderBy(orderBy, orderDesc)}
                    <br />
                    {max < stats.length ? <div href="#" onClick={(e) => toggleMore(e)} className="show-all show-more">Voir plus</div> : ''}
                    {showAll && max === stats.length ? <div href="#" onClick={(e) => toggleMore(e)} className="show-all show-less">Voir moins</div> : ''}
                </div>
                <div className="data-grid cols-7" onClick={(e) => orderChange(e)}>
                    {renderHeadList('stats', orderBy, orderDesc)}
                    {lineStats}
                </div>
            </div>;
}

export default RenderStats;