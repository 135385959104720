import React from 'react';
import Loader from './Loader';
import Utils from '../libraries/Utils';

/**
 * Retourne le contenu HTML pour le loader
 */
export const RenderLoader = () => {
    return <div>
        <Loader />
    </div>;
}

export const renderHeadList = (typeList, orderBy, orderDesc) => {
    const head = [];
    const showOrder = typeList !== 'total';

    if (typeList === 'stats') {
        head.push(<div className="box order-col" key="head-name">Nom {showOrderAct('key', orderBy, orderDesc)}</div>);
        head.push(<div className="box order-col" key="head-count">Nb Sorties {!showOrder || showOrderAct('count', orderBy, orderDesc)}</div>,);
    } else if (typeList === 'list') {
        head.push(<div className="box order-col" key="head-date">Date {showOrderAct('start_time', orderBy, orderDesc)}</div>);
    } else {
        head.push(<div className="box order-col" key="head-count">Nb Sorties {!showOrder || showOrderAct('count', orderBy, orderDesc)}</div>,);
    }

    head.push(<div className="box order-col" key="head-time">Durée {!showOrder || showOrderAct('total_time', orderBy, orderDesc)}</div>);
    head.push(<div className="box order-col" key="head-distance">km {!showOrder || showOrderAct('total_distance', orderBy, orderDesc)}</div>);

    if (typeList !== 'list') {
        head.push(<div className="box order-col" key="head-avg-dst">Km/sortie {!showOrder || showOrderAct('avg_dst', orderBy, orderDesc)}</div>);
    }

    head.push(<div className="box order-col" key="head-avg-speed">Moyenne {!showOrder || showOrderAct('avg_speed', orderBy, orderDesc)}</div>);
    head.push(<div className="box order-col" key="head-energy">Kcal {!showOrder || showOrderAct('energy', orderBy, orderDesc)}</div>);

    if (typeList === 'list') {
        head.push(<div className="box order-col" key="head-tags">Tags</div>);
    }

    return head;
}

export const showOrderAct = (orderBy, currentOderBy, orderDesc) => {
    const isOrderedBy = currentOderBy === orderBy;
    return <div className="order-by">
        <a key={"order-asc-"+orderBy} data-order-by={orderBy} data-order-sort="asc">
            {isOrderedBy && !orderDesc ? <span className="order-by-icon">&#9650;</span> : <span className="order-by-icon">&#9651;</span>}
        </a>
        <a key={"order-desc-"+orderBy} data-order-by={orderBy} data-order-sort="desc">
            {isOrderedBy && orderDesc ? <span className="order-by-icon">&#9660;</span> : <span className="order-by-icon">&#9661;</span>}
        </a>
    </div>;
}

export const labelColOrderBy = (orderBy, orderSort) => {
    const label = {
        'key' : 'Nom',
        'start_time' : 'Date',
        'total_time' : 'Durée',
        'count' : 'Nb Sorties',
        'total_distance' : 'Km',
        'avg_dst' : 'Km/sortie',
        'avg_speed' : 'Moyenne',
        'energy' : 'Kcal'
    };

    return <small>trié par {label[orderBy]} {!orderSort ? <span>&#9650;</span> : <span>&#9660;</span>}</small>;
}

export const getTitleFilter = (filter) => {
    let title = '';
    if (filter.key === 'year') {
        title = filter.value;
    } else if (filter.key === 'month') {
        const data = filter.value.split('-');
        title = Utils.getLabelMonth(parseInt(data[1]) - 1) + " " + data[0];
    } else if (filter.key === 'week') {
        const data = filter.value.split('-');
        const date = new Date();
        date.setDate(parseInt(data[2]) * 7 - 6);
        date.setMonth(parseInt(data[1]) - 1);
        date.setYear(parseInt(data[0]));
        title = Utils.getLabelWeekOfDate(date.getTime() / 1000);
    } else if (filter.key === 'type_id') {
        const lableType = Utils.getLabelType();
        title = lableType[filter.value];
    }

    return title;
}