class Utils {
    static digitDate(value) {
        return ("0" + value).slice(-2);
    }

    static formatDate(date_to_format) {
        const date = new Date();
        date.setTime(date_to_format*1000);

        const hours = this.digitDate(date.getHours());
        const min = this.digitDate(date.getMinutes());

        return date.getDate() + " " + this.getLabelMonth(date.getMonth()) +" " + date.getFullYear() + " " + hours + ':' + min;
    }

    static getYearOfDate(date_to_format) {
        const date = new Date();
        date.setTime(date_to_format*1000);

        return date.getFullYear() ;
    }

    static getMonthAndYearOfDate(date_to_format) {
        const date = new Date();
        date.setTime(date_to_format*1000);

        return date.getFullYear() + "-" + this.digitDate(date.getMonth() + 1);
    }

    static getWeekOfDate(date_to_format) {
        const date = new Date();
        date.setTime(date_to_format*1000);

        const startYear = new Date(date.getFullYear(), 0, 1);

        const diffDay = Math.floor((date - startYear) / (24 * 60 * 60 * 1000)) + (startYear.getDay() ? startYear.getDay() : 6);

        return this.digitDate(Math.ceil(diffDay/7));
    }

    static getLabelWeekOfDate(date_to_format) {
        const start_week = new Date();
        const end_week = new Date();
        start_week.setTime(date_to_format*1000);
        end_week.setTime(date_to_format*1000);

        const startDate = start_week.getDate() + 1 - (start_week.getDay() ? parseInt(start_week.getDay()) : 6);
        start_week.setDate(startDate);
        end_week.setDate(startDate + 6);

        return this.digitDate(start_week.getDate()) + " au " + this.digitDate(end_week.getDate()) + " " + this.getLabelMonth(end_week.getMonth()) +" " + end_week.getFullYear();
    }

    static getLabelMonth(month) {
        const Month = {
            0: 'Janvier',
            1: 'Février',
            2: 'Mars',
            3: 'Avril',
            4: 'Mai',
            5: 'Juin',
            6: 'Juillet',
            7: 'Aout',
            8: 'Septembre',
            9: 'Octobre',
            10: 'Novembre',
            11: 'Décembre'
        };

        return Month[parseInt(month)];
    }

    static formatSeconds(seconds) {
        let time = '';
        let day = '';
        if (seconds >= 86400) {
            day = Math.floor(seconds/86400)+"j ";
            seconds = seconds - 86400*Math.floor(seconds/86400);
        }
        if (seconds >= 3600) {
            const hours = Math.floor(seconds/3600);
            time = (hours < 10 ? "0"+hours : hours) + ':';
            seconds = seconds - 3600*hours;
        } else {
            time = '00:';
        }
        const min = Math.floor(seconds/60);
        time += min < 10 ? "0"+min : min;
        seconds = seconds - 60*min;
        return day + time + ':' + (seconds < 10 ? "0"+seconds : seconds);
    }

    static getGroupBy = () => {
        return {
            year : 'Par année',
            month: 'Par mois',
            work : 'Trajet Boulot',
            home_st_mard : 'Autour St Mard',
            home_breti : 'Autour Breti',
            holidays : 'Par Vacances'
        }
    }

    /**
     * Retourne les types des labels
     *
     * @return {Object} Liste des types de labels
     */
    static getLabelType = () => {
        return {
            4 : 'Vacances',
            5 : 'Trajet Boulot',
            6 : 'Autour St Mard',
            8 : 'Autour Breti',
            7 : 'Autre'
        }
    }

    static getUrl = () => {
        return "/";
    }
}

export default Utils;

