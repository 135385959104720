import Utils from '../libraries/Utils';
import { Link } from "react-router-dom";

const renderTags = (tags, workoutId) => {
    const tagsHtml = [];
    for (const i in tags) {
        tagsHtml.push(
            <Link key={"tags-"+workoutId+'-'+tags[i].id} className="tags"
                to={Utils.getUrl() + 'filter/tags/' + tags[i].id}
                data-tag-id={tags[i].id}>{tags[i].name}</Link>
        );
    }

    return tagsHtml;
};

const RenderWorkout = ({workout}) => {
        const grid = [];

        const distance = Math.round(parseInt(workout.total_distance)/10)/100;

        grid.push(<div className="box" key={"w-"+workout.id+"-date"}>{Utils.formatDate(workout.start_time)}</div>);
        grid.push(<div className="box" key={"w-"+workout.id+"-time"}>{Utils.formatSeconds(workout.total_time)}</div>);
        grid.push(<div className="box" key={"w-"+workout.id+"-distance"}>{distance} km</div>);
        grid.push(<div className="box" key={"w-"+workout.id+"-avg-speed"}>{workout.avg_speed} km/h</div>);
        grid.push(<div className="box" key={"w-"+workout.id+"-energy"}>{workout.energy}</div>);
        grid.push(<div className="box" key={"w-"+workout.id+"-tags"}>{renderTags(workout.all_tags, workout.id)}</div>);

        return grid;
}

export default RenderWorkout;