import { labelColOrderBy, getTitleFilter, renderHeadList } from './RenderHTML';
import { useState } from 'react';
import { Link } from "react-router-dom";
import Utils from '../libraries/Utils';
import RenderWorkout from './RenderWorkout';
import { WorkoutsDataHook } from '../libraries/WorkoutData';

const RenderworkoutsData = ({max, filter}) => {
    const [orderBy, setOrderBy] = useState('start_time');
    const [orderDesc, setOrderDesc] = useState(true);
    const [{workouts}, dispatch] = WorkoutsDataHook();

    const workoutsData = workouts.getByFilterAndSort('cycling', filter, orderBy, orderDesc ? 'desc' : 'asc');
    if ('key' in filter && filter.key) {
        max = workoutsData.length;
    }

    const orderChange = (e) => {
        if (e.target.classList.contains('order-by-icon')) {
            if (e.target.parentNode.dataset.orderBy) {
                if (e.target.parentNode.dataset.orderBy !== orderBy) {
                    setOrderBy(e.target.parentNode.dataset.orderBy);
                }
                if (e.target.parentNode.dataset.orderSort === 'desc' && !orderDesc) {
                    setOrderDesc(true);
                } else if (e.target.parentNode.dataset.orderSort !== 'desc' && orderDesc) {
                    setOrderDesc(false);
                }
            }
        }
        return false;
    };

    const lineData = [];
    const lineTags = [];

    let countShow = 0;
    let totalShow = {
        name : '',
        count : 0,
        total_time : 0,
        total_distance : 0,
        avg_speed : 0.0,
        energy : 0
    };

    if ('key' in filter) {
        if (filter.key === 'tags' && filter.value !== 0) {
            for (const [key, value] of Object.entries(workoutsData[0].all_tags)) {
                if (value.id === parseInt(filter.value)) {
                    totalShow.name = value.name;
                }
            }
        } else {
            totalShow.name = getTitleFilter(filter);
        }
    }


    for (let k = 0; k < Math.min(max, workoutsData.length); k++) {
        if ('key' in filter) {
            totalShow.total_time += parseInt(workoutsData[k].total_time);
            totalShow.total_distance += parseInt(workoutsData[k].total_distance);
            totalShow.avg_speed += parseFloat(workoutsData[k].avg_speed);
            totalShow.energy += parseInt(workoutsData[k].energy);
        }

        lineData.push(<RenderWorkout key={"w-"+workoutsData[k].id} workout={workoutsData[k]} />);

        ++countShow;
    }

    if ('key' in filter) {
        lineTags.push(<div className="box top-tag" key="tag-name">{totalShow.name}</div>);
        lineTags.push(<div className="box top-tag" key="tag-time">{Utils.formatSeconds(totalShow.total_time)}</div>);
        lineTags.push(<div className="box top-tag" key="tag-distance">{Math.round(parseInt(totalShow.total_distance)/10)/100} km</div>);
        lineTags.push(<div className="box top-tag" key="tag-avg_speed">{Math.round(totalShow.avg_speed*100/countShow)/100} km/h</div>);
        lineTags.push(<div className="box top-tag" key="tag-energy">{totalShow.energy}</div>);
        lineTags.push(<div className="box top-tag" key="tag-tags"></div>);
    }

    return <div className="lists">
                <div className="title">
                    Top {countShow} des sorties
                    <br clear="all"/>
                    {labelColOrderBy(orderBy, orderDesc)}
                    <br clear="all"/>
                    {'key' in filter ? <Link to={Utils.getUrl()} className="show-all clear-filter">Tout</Link> : ''}
                </div>
                <div className="data-grid cols-6" onClick={(e) => orderChange(e)}>
                    {renderHeadList('list', orderBy, orderDesc)}
                    {'key' in filter ? lineTags : ''}
                    {lineData}
                </div>
            </div>
};

export default RenderworkoutsData;